import { gql } from '@apollo/client';
import Fragments from '../fragments';
// import Fragemets from '../fragments';

export const APPLICATION_FORM = gql`
  query($opportunity: String!, $parent_id: String) {
    applicationForm(slug: $opportunity) {
      id
      opportunity {
        ...opportunityMini
      }
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        ... on SelectField {
          choices
        }
        ... on GroupField {
          child_fields {
            id
            label
            type
            slug
            subtype
            is_required
            ... on SelectField {
              choices
            }
          }
        }
      }
      supported_languages
      is_skipped

      terms
      is_active
      max_members
    }

    instruments(parent_id: $parent_id, orderBy: {field: SLUG, order: ASC }) {
      id
      name
      slug
      created_at
      parent {
        id
        name
      }
      children {
        id
        name
      }
    }
    genres(orderBy: {field: SLUG, order: ASC }) {
      id
      name
      slug
    }
    types {
      id
      name
    }
  }
  ${Fragments.opportunityMini}
`;

export const OPPORTUNITY_FORM = gql`
  query opportunityForm($opportunity: ID!, $type: String) {
    opportunityForm(opportunity: $opportunity, type: $type) {
      id
      opportunity {
        ...opportunityMini
      }
      fields {
        id
        # label
        type
        order
        slug
        is_required
        ... on BuilderField {
          options
          choices
          label
          child_fields {
            id
            slug
          }
        }
      }
      terms
      supported_languages
      is_active
      is_skipped
      max_members
    }
  }

  ${Fragments.opportunityMini}
`;

export const OPPORTUNITY_BRIEF = gql`
  query($slug: String!) {
    opportunity(slug: $slug) {
      ...opportunityCard
      ...opportunityReviewer
      ...opportunityPayment
    }
  }
  ${Fragments.opportunityCard}
  ${Fragments.opportunityReviewer}
  ${Fragments.opportunityPayment}
`;

export const OPPORTUNITY_APPLICATIONS = gql`
  query($id: ID!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    opportunityApplications(id: $id, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        payment_status
        rate
        user_rate
        created_at
        email
        avatar
        address
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        name
        artist_name
      }
    }
  }
  ${Fragments.paginator}
`;

export const USER_APPLICATIONS = gql`
  query($first: Int!, $page: Int) {
    userApplications(first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        rate
        user_rate
        created_at
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        opportunity {
          id
          title
          slug
          organisation {
            id
            slug
            name
            email
            logo {
              name
              path
              thumb
            }
          }
          logo {
            name
            thumb
            path
          }
          genres {
            name
          }
          deadline_date
          start_date
        }
        created_at
      }
    }
  }
  ${Fragments.paginator}
`;

export const GET_USER_APPLICATIONS = gql`
  query($filters: ApplicationFilterInput, $first: Int!, $page: Int) {
    userApplications(filters: $filters, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        payment_status
        rate
        user_rate
        created_at
        address
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        opportunity {
          id
          title
          slug
          
          organisation {
            slug
            name
          }
          logo {
            name
            thumb
            path
          }
          deadline_date
          start_date
          end_date
          is_virtual
          
        }
        created_at
      }
    }
  }
  ${Fragments.paginator}
`;

export const APPLICATION_OPPORTUNITY_QUERY = gql`
  query($id: ID!) {
    applicationOpportunity(id: $id) {
      title
      slug
      organisation {
        slug
        name
        logo {
          name
          path
          thumb
        }
        account {
          account_id
        }
      }
      logo {
        name
        thumb
        path
      }
      genres {
        name
      }
      payment_details {
        title
        cost
        code
        currency
        slots
        description
      }
      coupons {
        id
        name
        code
        value
        slots
        is_active
      }
      deadline_date
      start_date
    }
  }
`;

export const APPLICATION_DETAILS = gql`
  query($id: ID!) {
    viewApplication(id: $id) {
      id
      status
      code
      #     opportunity
      user {
        id
        name
        email
        avatar {
          name
          thumb
          placeholder
        }
      }
      opportunity {
        id
        title
        slug
        organisation {
          id
          slug
          name
          email
          logo {
            name
            path
            thumb
          }
        }
        logo {
          name
          thumb
          path
        }
        genres {
          name
        }
        types {
          id
          name
        }
        payment_details {
          title
          cost
          code
          currency
          slots
          description
        }
        coupons {
          id
          name
          code
          value
          slots
          is_active
        }
        deadline_date
        start_date
      }
      name
      artist_name
      email
      address
      elements {
        # id
        field_name
        type
        subtype
        ... on ApplicationScalarElement {
          value
        }
        ... on ApplicationFilesElement {
          files {
            name
            path
            thumb
          }
        }
        ... on ApplicationGroupElement {
          child_elements {
            field_name
            type
            value
          }
        }
      }
      rate
      user_rate
      created_at
      payment_status
      refunded_at

      ...applicationPayment
    }
  }
  ${Fragments.applicationPayment}
`;

export const SEARCH_OPPORTUNITY = gql`
  query($input: OpportunityFilterInput, $first: Int!, $page: Int) {
    opportunitySearch(input: $input, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        ...opportunityCard
      }
    }
  }

  ${Fragments.opportunityCard}
  ${Fragments.paginator}
`;

export const VIEW_OPPORTUNITY = gql`
  query($slug: String!) {
    opportunity(slug: $slug) {
     ...opportunityDetails
    }
  }
  ${Fragments.opportunityDetails}
`;

// export const OPPORTUNITY_DETAILS_PAGE = gql`
//   query($slug: String!, $input: OpportunityFilterInput) {
//     opportunity(slug: $slug) {
//       ...opportunityDetails
//     }
//     opportunitySearch(input: $input, first: 4) {
//       paginatorInfo {
//         ...paginator
//       }
//       data {
//         ...opportunityCard
//       }
//     }
//   }
//   ${Fragments.opportunityDetails}
//   ${Fragments.opportunityCard}
//   ${Fragments.paginator}
// `;

export const OPPORTUNITY_COUPONS = gql`
  query opportunityCoupons($opportunity: String!) {
    opportunityCoupons(opportunity: $opportunity) {
      ...couponMini
      #     created_by
    }
    opportunity(slug: $opportunity) {
      id
      title
      slug
      description
      user {
        id
      }
      organisation {
        id
        slug
        name
        email
        logo {
          name
          path
          placeholder
          thumb
        }
        city {
          id
          name
          country {
            name
          }
        }
      }
      ...opportunityPayment
    }
  }
  ${Fragments.couponMini}
  ${Fragments.opportunityPayment}
`;

export const GET_COUPON = gql`
  query getCoupon($code: String!, $opportunity: String!) {
    getCoupon(code: $code, opportunity: $opportunity) {
      ...couponMini
      # opportunity
      # created_by
    }
  }
  ${Fragments.couponMini}
`;

export const OPPORTUNITY_REVENUE = gql`
  query opportunityRevenue($opportunitySlug: String!) {
    opportunityRevenue(opportunitySlug: $opportunitySlug) {
      total_applications
      collected_revenue {
        amount
        currency
      }
      available_revenue {
        amount
        currency
      }
      refunded_revenue {
        amount
        currency
      }
    }
  }
`;

export const OPPORTUNITY_STATS = gql`
  query getOpportunityStats($slugs: [String!],  $filters: InsightsFiltersInput) {
    opportunityStats(slugs: $slugs, filters: $filters) {
      opportunities{
        slug
        title
        description
      }
      applications_count
      totalRefunds
      is_paid
      instrumentCounts {
        instrument_name
        count
      }
      genderCounts {
        name
        count
      }
      locationStats {
        country
        location_count
      }
      ageStats {
        age_group
        count
      }
      applicationStats {
        date_interval,
        count,
      }
      viewsStats {
        date_interval,
        count,
      }
      paymentStats {
        date_interval,
        value,
        count
      }
    }
  }
`;
export const WIDGETS_DATA = gql`
  query getWidgetsData($opportunity: String!, $fieldId: String! $viewType: String!, $dataType: String! ) {
    widgetsData(opportunity: $opportunity, fieldId: $fieldId viewType: $viewType, dataType: $dataType) {
      instruments {
        name
        count
      }
      location {
        name
        count
      }
      genres {
        name
        count
      }
      datetime_field {
        name
        count
      }
      line_graph {
        name
        count
      }
      multiple {
        name
        count
      }
      list {
        name
        count
        percentage
      }
      group_members {
        group_members_instruments {
          name
          count
        }
        group_members_locations {
          name
          count
        }
        group_members_avg_age
      }
      payments {
        name
        count
      }
    }
  }
`;
