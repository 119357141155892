import { gql } from '@apollo/client';
import Fragments from '../fragments';

export const GET_APPLICATION_COMMENTS = gql`
  query applicationComments($id: ID!, $is_comment: Boolean, $first: Int!, $page: Int) {
    applicationComments(id: $id, is_comment: $is_comment, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        content
        user {
          ...UserInformation,
        }
        # created_at
        # created_at
        # is_reply
      }
    }
  }
  ${Fragments.userInfo}
`;

export const GET_APPLICATION_MESSAGES = gql`
  query applicationMessages($application: ID!, $first: Int!, $page: Int) {
    applicationMessages(application: $application, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        message
        user_id
        created_at
      }
    }
  }
`;

export const APPLICATION_STATUS_QUERY = gql`
  query applicationStatus($id: ID!) {
    applicationStatus(id: $id) {
      name
      opportunity {
        ...opportunityMini
      }
      payment {
        title
        cost
        code
        currency
        slots
        description
      }
      payment_status
      payment_info  {
        receipt
        coupon
      }
      status
    }
  }
  ${Fragments.opportunityMini}
`;


