import { gql } from '@apollo/client';
import Fragments from '../fragments';

export const MY_ORGANISATIONS_QUERY = gql`
  query {
    myOrganisations {
      ...organisationCard
      ...orgAccount
    }
  }

  ${Fragments.orgAccount}
  ${Fragments.organisationCard}
`;

/**
 * @used organisation update
 */
export const GET_ORGANISATION_DETAILS = gql`
  query getOrganisation($slug: String!) {
    organisation(slug: $slug) {
      ...organisationCard
      ...organisationAdditionalInfo
      account {
        ...accountInfo
      }
    }
  }

  ${Fragments.accountInfo}
  ${Fragments.organisationCard}
  ${Fragments.organisationAdditionalInfo}
`;

export const MY_OPPORTUNITIES = gql`
  query getMyOpportunities($type: String, $organisation: String, $first: Int!, $page: Int, $search: String) {
    myOpportunities(type: $type, organisation: $organisation, first: $first, page: $page, search: $search) {
      paginatorInfo {
        ...paginator
      }
      data {
        ...opportunityCard
      }
    }
  }
  ${Fragments.opportunityCard}
  ${Fragments.paginator}
`;
export const ORGANISATION_OPPORTUNITIES = gql`
  query getOrganisationOpportunities($organisation: String!) {
    organisationOpportunities(organisation: $organisation) {
        ...opportunityCard
        created_at
    }
  }
  ${Fragments.opportunityCard}
`;

export const GET_ORGANISATION_ACCOUNT = gql`
  query organisationAccount($organisation: ID!, $recheck: Boolean) {
    organisationAccount(organisation: $organisation, recheck: $recheck) {
      ...accountInfo
    }
  }
  ${Fragments.accountInfo}
`;

export const ORGANISATION_ACCOUNT_DETAILS = gql`
  query organisationAccount($organisation: ID!, $slug: String!, $recheck: Boolean) {
    organisationAccount(organisation: $organisation, recheck: $recheck) {
      ...accountInfo
    }
    organisation(slug: $slug) {
      ...organisationCard
    }
     accountBalance(organisation: $organisation) {
       ...accountBalance
     }
  }
  ${Fragments.accountInfo}
  ${Fragments.organisationCard}
  ${Fragments.accountBalance}

`;

export const VERIFY_ACCOUNT_LINK = gql`
  query verifyAccountLink($organisation: String!) {
    verifyAccountLink(organisation: $organisation) {
      ...connectStep
    }
  }
  ${Fragments.connectStep}
`;

export const ORGANISATION_PAYOUTS = gql`
  query organisationPayouts($organisation: String!) {
    organisationPayouts(organisation: $organisation) {
      id
      amount
      currency
      description
      failure_code
      #     account
      created_by {
        name
      }
      created_at
      arrival_date
    }
  }
`;

export const UPDATE_ACCOUNT_LINK = gql`
  query updateAccountLink($organisation: String!) {
    updateAccountLink(organisation: $organisation) {
      ...connectStep
    }
  }
  ${Fragments.connectStep}
  `;

export const USER_ORGANISATIONS = gql`
 query getOrganisation {
    myOrganisations {
      name
      slug
      created_at
      logo {
        name
        path
      }
    }
  }
`;

export const ORGANISATION_APPLICANTS = gql`
  query OrganisationApplicants($slug: String!) {
    organisationApplicants(slug: $slug) {
        name
        email
        latitude
        longitude
        payment_status
        avatar
        user_id
        app_id
    }
  }
`;
export const OPPORTUNITY_APPLICANTS = gql`
  query opportunityApplicants($slugs: [String!]) {
    opportunityApplicants(slugs: $slugs) {
        name
        email
        latitude
        longitude
        payment_status
        avatar
        user_id
        app_id
    }
  }
`;

export const GET_COUNTRIES = gql`
  query {
    allCountries{
      name,
      code,
      lat,
      long
    }
  }
`;
export const ALL_APPLICANTS = gql`
  query getAllApplicants($oppSlugs: [String!], $orgSlug: String,  $type: String, $filter: String!) {
    allApplicants(oppSlugs: $oppSlugs, orgSlug: $orgSlug, type: $type, filter: $filter) {
        id
        name
        email
        created_at
        location_name
        location_name_formatted
        applications_count
        payment_status
        country
        status
        avatar
    }
  }
`;

export const ORGANISATION_STATS = gql`
  query getOrganisationStats($slug: String!, $filters: InsightsFiltersInput) {
    organisationStats(slug: $slug, filters: $filters) {
      slug
      name
      email
      description
      opportunities_count
      applications_count
      totalRefunds
      instrumentCounts {
        instrument_name
        count
      }
      genderCounts {
        name
        count
      }
      locationStats {
        country
        location_count
      }
      ageStats {
        age_group
        count
      }
      applicationStats {
        date_interval,
        count,
      }
      viewsStats {
        date_interval,
        count,
      }
      paymentStats {
        date_interval
        value
        count
      }
    }
  }
`;
